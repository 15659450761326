import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ImageBorder = styled.div<{ big?: boolean }>`
    position: relative;

    ::before {
        content: '';
        position: absolute;
        top: 3.37%;
        left: 4.87%;
        right: 0;
        height: 100%;
        width: 226px;
        border: 2px solid ${({ theme }) => theme.colors.yellow2};
        z-index: 2;
        border-radius: 500px;
    }

    > .gatsby-image-wrapper {
        border-radius: 500px;
        opacity: 0.9999;
    }

    ${({ big, theme }) =>
        big
            ? css`
                  @media (max-width: 1023px) {
                      ::before {
                          width: 212px;
                      }

                      > .gatsby-image-wrapper {
                          max-width: 212px;
                          max-height: 312px;
                      }
                  }
              `
            : css`
                  ::before {
                      width: 216px;
                      border: 2px solid ${theme.colors.yellow1};
                  }

                  > .gatsby-image-wrapper {
                      max-width: 216px;
                      max-height: 317px;
                  }
              `};
`;
