import { Article } from '@/Article';
import { flexSpace } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { useRef } from 'react';

const StyledArticle = styled(Article)`
    > h2 {
        max-width: 480px;
    }

    > div {
        p {
            max-width: 480px;
            margin: 0;

            :last-of-type {
                margin-top: 32px;
            }
        }
    }

    @media (min-width: 1024px) {
        > h2 {
            max-width: 840px;
        }

        > div {
            ${flexSpace};
            align-items: flex-start;

            p:last-of-type {
                margin-top: 0;
                margin-left: 32px;
            }
        }
    }

    @media (min-width: 1280px) {
        padding-bottom: 96px;
    }
`;

export const Intro = () => {
    const firstRef = useRef<HTMLParagraphElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    return (
        <StyledArticle big>
            <h2>THE BEST LIP INJECTIONS AND COSMETIC DERMATOLOGY SERVICES IN LOS ANGELES</h2>
            <div>
                <p className={`reveal${firstReveal ? ' revealed' : ''}`} ref={firstRef}>
                    <strong>Beauty Religion</strong> — an exceptional medical spa with two locations
                    (West Hollywood and Dana Point)—is committed to providing the best aesthetic
                    injections and cosmetic dermatology services in Los Angeles and Orange County!
                    We proudly serve clients residing in West Hollywood, Beverly Hills, Los Angeles,
                    Laguna Beach, and Dana Point. With our wide range of exceptional Regenerative
                    Aesthetics services, Beauty Religion is devoted to helping you feel and look
                    your best in a natural way. Our cosmetic dermatology and aesthetic medicine
                    services include Dermal fillers, Botox, Dysport, Laser Treatments, IPL, Sofwave,
                    Sculptra, Renuva, RF Microneedling Skin Tightening, Agnes, Body Sculpt, PRP, PRF
                    Gel, VI Peel, Mesotherapy, PDO Threads, Cliovana, Carboxytherapy, Varicose Vein
                    Injections, and much more!
                </p>
                <p
                    ref={secondRef}
                    className={`reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`}
                >
                    We exclusively specialize in aesthetic medicine, using the most modern,
                    effective and non-surgical techniques for facial rejuvenation in our
                    state-of-the-art office.
                </p>
            </div>
        </StyledArticle>
    );
};
