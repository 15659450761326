import { SecondaryBtn } from '@/buttons';
import CallBtn from '@/buttons/CallBtn';
import CLink from '@/CLink';
import { getNavLink, info } from '@/layout/NavLinks';
import { flexCenter } from '@/layout/styles/classes';
import { theme } from '@/layout/styles/GlobalStyles';
import appointmentIcon from '@a/icons/appointment.svg';
import { ReactComponent as Pin } from '@a/icons/pin.svg';
import { ReactComponent as Star } from '@a/icons/star.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const HomeHero = styled.section`
    display: grid;
    height: 100vh;
    margin-top: -84.69px;

    @media (max-width: 499px) {
        min-height: 800px;
    }

    @media (min-width: 500px) {
        min-height: 700px;
    }

    @media (min-width: 550px) {
        min-height: 670px;
    }

    @media (min-width: 768px) {
        margin-top: -86.23px;
    }

    @media (min-width: 1280px) {
        margin-top: -152px;
    }
`;

const bgStyles = css`
    grid-area: 1/1;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        grid-area: 1/1;
        background-color: ${theme.colors.gray3};
        mix-blend-mode: multiply;
        opacity: 0.6;
        z-index: 1;
    }

    @media (min-width: 1280px) {
        img {
            object-position: top center;
        }
    }
`;

const Box = styled.div`
    grid-area: 1/1;
    justify-self: center;
    align-self: end;
    padding: 0 16px;
    z-index: 3;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray1};
    margin-bottom: 32px;

    > h1 {
        font-size: 2rem;
        text-transform: uppercase;
        margin: 0 0 12px;
    }

    > h3 {
        margin: 0 0 16px;
        color: ${({ theme }) => theme.colors.green1};
        text-transform: uppercase;
        font-family: 'Manrope', sans-serif;
        font-size: 1.5rem !important;
    }

    > p:first-of-type {
        ${flexCenter};
        margin: 0 0 16px;

        > svg {
            margin-right: 3px;
        }

        > strong {
            margin-left: 12px;
            margin-right: 0.5ch;
        }
    }

    > div:last-of-type {
        margin-top: 16px;

        a {
            padding: 16px 28px;

            :last-of-type {
                display: none;
                margin-left: 32px;

                > img {
                    margin-right: 18px;
                }
            }
        }
    }

    @media (min-width: 768px) {
        > div:last-of-type {
            ${flexCenter};
            margin-top: 24px;

            a:last-of-type {
                display: inline-flex;
            }
        }
    }

    @media (min-width: 1024px) {
        > h1 {
            font-size: 3rem;
        }
    }

    @media (min-width: 1280px) {
        margin-bottom: 40px;
    }
`;

const Location = styled.div`
    ${flexCenter};
    align-items: flex-start;
    flex-wrap: wrap;

    :nth-of-type(2) {
        margin: 16px 0;
    }

    svg {
        flex-shrink: 0;
    }

    p {
        margin: 0 12px;
    }

    a {
        ${flexCenter};
        color: ${({ theme }) => theme.colors.gray1};
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
        text-align: left;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.pink1};
        }
    }

    > div > p {
        font-size: 0.75rem;
        margin: -2px 0 0;
        text-align: left;
        font-style: italic;
    }
`;

export const Hero = () => {
    return (
        <HomeHero>
            <Box>
                <h1>BEAUTY RELIGION</h1>
                <h3>WE BELIEVE IN BEAUTY</h3>
                <p>
                    <Star />
                    <Star />
                    <Star />
                    <Star />
                    <Star /> <strong>5.0 </strong> on Yelp
                </p>
                <Location>
                    <Pin /> <p>West Hollywood: </p>
                    <CLink to={info.address.link}>{info.address.text}</CLink>
                </Location>
                <Location>
                    <Pin /> <p>Dana Point: </p>
                    <div>
                        <CLink to={info.secondAddress.link}>{info.secondAddress.text}</CLink>
                        <p>East of Golden Lantern. Located inside Riviera Laser Studios</p>
                    </div>
                </Location>
                <div>
                    <CallBtn />
                    <SecondaryBtn white="true" big="true" as={CLink} to={getNavLink('appointment')}>
                        <img src={appointmentIcon} alt="appointment" />
                        Book Appointment
                    </SecondaryBtn>
                </div>
            </Box>
            <StaticImage
                src="../../../assets/images/homepage/hero.jpg"
                alt=" yeliana nikolskaya "
                loading="eager"
                css={bgStyles}
                quality={70}
            />
        </HomeHero>
    );
};
