import { Article } from '@/Article';
import { flexSpace } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { useRef } from 'react';

const Text = styled.div`
    background-color: ${({ theme }) => theme.colors.pink3};

    > article {
        & > h2 {
            color: ${({ theme }) => theme.colors.green2};
            max-width: 840px;
        }

        & > p {
            margin: 32px 0;
            max-width: 740px;
        }
    }

    @media (min-width: 1280px) {
        > article {
            & > p {
                margin: 64px 0;
            }
        }
    }
`;

const Cards = styled.div`
    display: grid;
    grid-template-columns: 1;
    gap: 32px;

    > div {
        max-width: 343px;

        p {
            margin: 0 0 16px;
        }

        .gatsby-image-wrapper {
            max-height: 337px;
        }
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 343px);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 226px);
        gap: 0;
        justify-content: space-between;

        > div {
            ${flexSpace};
            flex-direction: column;

            p {
                margin: 0 0 56px;
            }

            .gatsby-image-wrapper {
                height: 296px;
            }
        }
    }
`;

export const Aging = () => {
    type DataType = {
        allFile: {
            nodes: ImageDataLike[];
        };
    };

    const data: DataType = useStaticQuery(graphql`
        query agingImages {
            allFile(
                filter: { relativeDirectory: { eq: "homepage/aging" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(
                            width: 343
                            height: 377
                            transformOptions: { cropFocus: NORTH }
                        )
                    }
                }
            }
        }
    `);

    const firstRef = useRef<HTMLParagraphElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    const thirdRef = useRef<HTMLParagraphElement | null>(null);
    const thirdReveal = useIntersectionObserver(thirdRef, {});

    const fourthRef = useRef<HTMLParagraphElement | null>(null);
    const fourthReveal = useIntersectionObserver(fourthRef, {});

    const agesData = [
        {
            text: (
                <p key={1}>
                    <strong>Patients in their 30s</strong>, who have had limited sun exposure, often
                    show signs of aging first in their eyes, like crow’s feet, frown expressions,
                    and some depressions to lower eyelids.
                </p>
            ),
            className: `reveal${firstReveal ? ' revealed' : ''}`,
            ref: firstRef,
        },
        {
            text: (
                <p key={2}>
                    <strong>As patients enter their 40s</strong>, the midface begins to shift as the
                    cheeks lose fat and laugh lines set in. Much like a balloon losing air, the
                    cheeks become deflated toward the late 40s and the jowls begin to sag.
                </p>
            ),
            className: `reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`,
            ref: secondRef,
        },
        {
            text: (
                <p key={3}>
                    <strong>By the mid-50s</strong>, the skin has relaxed and requires the most
                    attention around the neck. In addition, the skin around the face usually has
                    fallen and repositioned itself.
                </p>
            ),
            className: `reveal reveal-delay-2${thirdReveal ? ' revealed' : ''}`,
            ref: thirdRef,
        },
        {
            text: (
                <p key={4}>
                    <strong>In the 60s and 70s</strong>, individuals often battle hollowness from
                    losing a large quantity of facial fat and having a sizeable amount of hanging
                    skin.
                </p>
            ),
            className: `reveal reveal-delay-3${fourthReveal ? ' revealed' : ''}`,
            ref: fourthRef,
        },
    ];

    const ages = data.allFile.nodes.map((node, i) => ({
        img: getImage(node) as IGatsbyImageData,
        ...agesData[i],
    }));

    return (
        <section>
            <Text>
                <Article big>
                    <h2>
                        AGING OF THE FACE BY DECADES – BOOK AN APPOINTMENT AT YOUR AESTHETIC NURSE
                        PRACTITIONER IN WEST HOLLYWOOD
                    </h2>
                    <p>
                        The composition of the human face varies person to person. As we age and
                        enter certain decades of life, these variations become more distinct.
                        Different facial anatomy requires different non-surgical treatments to
                        achieve optimal results in facial rejuvenation. Here&apos;s some common
                        changes that occur:
                    </p>

                    <Cards>
                        {ages.map((age, i) => (
                            <div key={i} className={age.className} ref={age.ref}>
                                {age.text}
                                <GatsbyImage
                                    image={age.img}
                                    alt="smiling women with beautiful skin"
                                    objectPosition="top"
                                />
                            </div>
                        ))}
                    </Cards>
                </Article>
            </Text>
        </section>
    );
};
