import { Article } from '@/Article';
import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';

const TheArticle = styled(Article)`
    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }
`;

const Card = styled.div`
    max-width: 504px;

    :first-of-type {
        margin-bottom: 56px;
    }

    > h2 {
        color: ${({ theme }) => theme.colors.green2};
        margin: 24px 0 16px;
    }

    > p {
        margin: 0 0 16px;

        a {
            transition: color 0.3s ease-in-out;
            color: ${({ theme }) => theme.colors.green2};

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.green1};
            }
        }
    }

    @media (min-width: 1024px) {
        :last-of-type {
            margin-top: 150px;
            margin-left: 32px;
        }
    }
`;

export const TwoServices = () => {
    const firstRef = useRef<HTMLParagraphElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    return (
        <TheArticle big>
            <Card className={`reveal${firstReveal ? ' revealed' : ''}`} ref={firstRef}>
                <StaticImage
                    src="../../../assets/images/homepage/two-services/botox.jpg"
                    alt="yeliana nikolskaya holding botox injection"
                    width={504}
                    height={592}
                />
                <h2>Fillers</h2>
                <p>
                    Beauty religion is proud to offer several different Dermal Fillers to smooth
                    moderate to deep lines, wrinkles, and skin folds. We also use Dermal Fillers to
                    increase the volume of under-eye hollows, lips, cheeks, chin, jawline, and the
                    back of hands to regain youthful fullness. We are experts at achieving natural
                    results with fillers that will last a year or more.
                </p>
                <SecondaryBtn as={CLink} to={getNavLink('dermal fillers') as string}>
                    Explore our Filler Service
                </SecondaryBtn>
            </Card>
            <Card
                className={`reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`}
                ref={secondRef}
            >
                <StaticImage
                    src="../../../assets/images/homepage/two-services/anti-aging.jpg"
                    alt="yeliana nikolskaya holding a product"
                    width={504}
                    height={592}
                />
                <h2>anti-aging</h2>
                <p>
                    Beauty Religion has all your anti-aging solutions in one place. Our mission is
                    for you to leave your appointment looking refreshed, rejuvenated, and radiant
                    with a relaxed, more youthful appearance.
                </p>
                <SecondaryBtn as={CLink} to={getNavLink('treatments') as string}>
                    Explore our Treatments
                </SecondaryBtn>
            </Card>
        </TheArticle>
    );
};
