import { Article } from '@/Article';
import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { MiniArticle } from '@/MiniArticle';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';

const Bt = styled.section`
    display: grid;
    position: relative;
    z-index: 1;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.63);
        z-index: 2;
    }

    > * {
        grid-area: 1/1;
        z-index: 1;
    }

    > article {
        align-self: center;
    }

    > .gatsby-image-wrapper {
        max-height: 630px;
    }

    > ${Article} {
        z-index: 3;

        @media (min-width: 600px) {
            width: 100%;
        }
    }
`;

export const Botox = () => {
    const ref = useRef<HTMLParagraphElement | null>(null);
    const reveal = useIntersectionObserver(ref, {});

    return (
        <Bt>
            <StaticImage
                src="../../../assets/images/homepage/botox.jpg"
                alt="woman getting botox"
                layout="fullWidth"
            />
            <Article big ref={ref} className={`reveal-slide-left${reveal ? ' revealed' : ''}`}>
                <MiniArticle white>
                    <h2>BOTOX</h2>
                    <p>
                        Botox has been used for years to successfully smooth fine, moderate, and
                        even severe lines and wrinkles. With no downtime and full results in a few
                        days that last for months, it’s time to see what Botox can do for you.
                    </p>
                    <SecondaryBtn white="true" as={CLink} to={getNavLink('botox')}>
                        Explore our Botox Service
                    </SecondaryBtn>
                </MiniArticle>
            </Article>
        </Bt>
    );
};
