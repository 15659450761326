import { Article } from '@/Article';
import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { flexSpace } from '@/layout/styles/classes';
import { MiniArticle } from '@/MiniArticle';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';

const TheArticle = styled(Article)`
    ${MiniArticle} {
        margin-top: 32px;
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        margin-left: 0;
        max-width: 1172px;

        ${MiniArticle} {
            margin-top: 0;
            margin-left: 32px;
        }
    }

    @media (min-width: 1600px) {
        margin-left: 5vw;
    }
`;

export const Laser = () => {
    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    return (
        <TheArticle big>
            <div ref={firstRef} className={`reveal${firstReveal ? ' revealed' : ''}`}>
                <StaticImage
                    src="../../../assets/images/homepage/laser-treatment.jpg"
                    alt="woman getting laser treatment"
                    width={542}
                    height={713}
                />
            </div>
            <MiniArticle
                ref={secondRef}
                className={`reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`}
            >
                <h2>laser treatment</h2>
                <p>
                    IPL Photofacial and laser hair removal treatments have become massively popular
                    in the past decades! Beauty Religion is committed to servicing Beverly Hills,
                    West Hollywood, and Los Angeles. We provide IPL photo facial and laser removal
                    services for our patients’ beauty needs and desires. Our services always bring
                    amazing and long-lasting results.
                </p>
                <h6>Laser Treatment services:</h6>
                <ul>
                    <li>IPL Fotofacial</li>
                    <li>Laser Hair Removal </li>
                    <li>Erbium Laser </li>
                    <li>Vein Diode Laser </li>
                    <li>Tattoo Removal Yag Laser</li>
                </ul>
                <SecondaryBtn as={CLink} to={getNavLink('laser treatments')}>
                    Explore our Laser Services
                </SecondaryBtn>
            </MiniArticle>
        </TheArticle>
    );
};
