import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { flexCenter, px } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import '@fontsource/alex-brush';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';

const H2 = styled.h2`
    ${px};
    color: ${({ theme }) => theme.colors.green2};
    padding-top: 64px;
    padding-bottom: 64px;
    margin: 0;
    text-align: center;

    @media (min-width: 1024px) {
        padding-top: 104px;
        padding-bottom: 93px;
    }

    @media (min-width: 1280px) {
        padding-top: 144px;
        padding-bottom: 123px;
    }
`;

const Person = styled.div`
    background-image: ${({ theme }) => `linear-gradient(
                transparent 360px,
                ${theme.colors.pink3} 360px
            )`};
    padding-bottom: 64px;

    @media (max-width: 320px) {
        background-image: ${({ theme }) => `linear-gradient(
                transparent 190px,
                ${theme.colors.pink3} 190px
            )`};
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        padding-bottom: 96px;
        background-image: ${({ theme }) => `linear-gradient(
                transparent 45%,
                ${theme.colors.pink3} 45%
            )`};
    }

    @media (min-width: 1280px) {
        padding-bottom: 100px;
    }
`;

const Wrapper = styled.div`
    ${px};
    background-image: ${({ theme }) =>
        `linear-gradient(transparent 50%, ${theme.colors.pink3}  50%)`};

    @media (min-width: 1024px) {
        padding: 0 !important;
        background-image: none;
    }
`;

const Content = styled.div`
    ${px};
    max-width: 612px;
    box-sizing: content-box;

    > h3 {
        margin: 32px 0 16px;
        text-transform: capitalize;

        a {
            color: ${({ theme }) => theme.colors.green2};
            transition: text-decoration-color 0.3s ease-in-out;
            text-decoration: underline rgba(0, 0, 0, 0);

            :hover,
            :focus {
                text-decoration-color: ${({ theme }) => theme.colors.green2};
            }
        }
    }

    > p {
        :first-of-type {
            margin: 0 0 16px;
            color: ${({ theme }) => theme.colors.gray3};
        }

        :last-of-type {
            text-align: right;
            font-size: 2rem;
            line-height: 125%;
            color: ${({ theme }) => theme.colors.green2};
            margin: 0;
            font-family: 'Alex Brush';
        }
    }

    @media (min-width: 1024px) {
        flex-shrink: 15;

        > h3 {
            margin: 0;
        }

        > p {
            :first-of-type {
                margin: 32px 0;
            }

            :last-of-type {
                font-size: 2.5rem;
            }
        }
    }

    @media (min-width: 1280px) {
        padding: 0 !important;
        margin-left: 108px;
    }
`;

export const Team = () => {
    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, { threshold: 0.3 });

    return (
        <section>
            <H2>meet The Provider</H2>
            <Person>
                <Wrapper>
                    <StaticImage
                        src="../../../assets/images/homepage/yeliana.jpg"
                        alt="yeliana nikolskaya"
                        width={542}
                        height={707}
                    />
                </Wrapper>
                <Content ref={firstRef} className={`reveal${firstReveal ? ' revealed' : ''}`}>
                    <h3>
                        <CLink to={getNavLink('yeliana nikolskaya')}>
                            Yeliana Nikolskaya
                            <br /> NP, RN, MSN
                        </CLink>
                    </h3>
                    <p>
                        Aesthetic Nurse Practitioner, specializes exclusively in facial rejuvenation
                        through various cosmetic procedures. Her expertise includes working with
                        various dermal fillers, botulinum toxins (such as Botox, Dysport, etc.), PDO
                        Threads, and Energy Devices (Lasers, IPL, RF skin tightening, Shock Wave).
                        <br />
                        <br />
                        With {new Date().getFullYear() - 2000} years of extensive experience in
                        cosmetic dermatology, Yeliana Nikolskaya has honed her skills and developed
                        a deep understanding of the intricacies of facial anatomy through daily
                        practice of aesthetic art and continued learning.
                        <br />
                        <br />
                        Yeliana stays up-to-date with the latest techniques and technologies in the
                        field of Aesthetic Medicine, ensuring that her patients have access to the
                        most modern and effective cosmetic procedures. Additionally, Yeliana
                        provides Regenerative procedures (PRF gel filler, Exosomes, BIHRT, Peptides,
                        Cliovana) for those who look for an all natural approach.
                        <br />
                        <br />
                        Yeliana’s dedication to her patients, her years of experience, and her
                        commitment to continuous education make her a sought-after practitioner for
                        those looking to receive top notch aesthetic care for enhancing their
                        natural beauty.
                    </p>
                    <p>Yeliana Nikolskaya</p>
                </Content>
            </Person>
        </section>
    );
};
