import { Article } from '@/Article';
import CLink from '@/CLink';
import { flatArray, navLinks } from '@/layout/NavLinks';
import { flexCenter, px } from '@/layout/styles/classes';
import { h3, h4 } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { useRef } from 'react';

const Section = styled.section`
    text-align: center;

    > ${Article} {
        max-width: 1300px;
    }

    @media (min-width: 1024px) {
        > ${Article} {
            padding-bottom: 88px;

            > h2 {
                margin-bottom: 96px;
            }
        }
    }

    @media (min-width: 1280px) {
        > ${Article} {
            padding-bottom: 144px;
            padding-top: 100px;

            > h2 {
                margin-bottom: 120px;
            }
        }
    }
`;

const Links = styled.div`
    display: grid;
    grid-auto-flow: row;
    margin-bottom: -20px;

    > a {
        ${h4};
        color: ${({ theme }) => theme.colors.gray3};
        text-transform: capitalize;
        margin-bottom: 20px;
    }

    @media (min-width: 768px) {
        ${flexCenter};
        flex-wrap: wrap;
        margin-bottom: 0;
        gap: 32px 0;

        a {
            padding: 0 32px;
            margin: 0;
            border-right: 1px solid ${({ theme }) => theme.colors.gray2};
            border-left: 1px solid ${({ theme }) => theme.colors.gray2};
            transition: color 0.3s ease-in-out;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.green2};
            }
        }
    }

    @media (min-width: 1024px) {
        gap: 48px 0;
    }

    @media (min-width: 1280px) {
        margin-top: 120px;
        gap: 64px 0;
    }
`;

const Bg = styled.div`
    ${px};
    background-color: ${({ theme }) => theme.colors.pink3};
    padding-top: 64px;
    padding-bottom: 64px;

    > p {
        ${h3};
        color: ${({ theme }) => theme.colors.green2};
        max-width: 775px;
        margin: 0 auto;
    }

    @media (min-width: 1280px) {
        padding-top: 148px;
        padding-bottom: 148px;
    }
`;

export const Services = () => {
    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, { threshold: 0.05 });

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    const serviceLinks = flatArray(navLinks[1]?.links ? navLinks[1].links : []);

    return (
        <Section>
            <Article>
                <h2>we offer services</h2>
                <Links ref={firstRef} className={`reveal${firstReveal ? ' revealed' : ''}`}>
                    {serviceLinks.map((link, i) => (
                        <CLink key={i} to={link.link}>
                            {link.text}
                        </CLink>
                    ))}
                </Links>
            </Article>

            <Bg>
                <p className={`reveal${secondReveal ? ' revealed' : ''}`} ref={secondRef}>
                    “Our staff is trained to perform all non-invasive cosmetic procedures, and that
                    has helped us develop the most comprehensive selection of non-surgical cosmetic
                    treatment options.”
                </p>
            </Bg>
        </Section>
    );
};
