import { Aging } from '@/homepage/Aging';
import { Botox } from '@/homepage/Botox';
import { Customers } from '@/homepage/Customers';
import { Hero } from '@/homepage/Hero';
import { Intro } from '@/homepage/Intro';
import { Laser } from '@/homepage/Laser';
import { Services } from '@/homepage/Services';
import { Store } from '@/homepage/Store';
import { Team } from '@/homepage/Team';
import { TwoServices } from '@/homepage/TwoServices';
import { Works } from '@/homepage/Works';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Home: Page = () => (
    <Fragment>
        <Helmet>
            <script type="application/ld+json">
                {`
                {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Beauty Religion",
                "image": "",
                "@id": "",
                "url": "https://beautyreligion.com/",
                "telephone": "(310) 299-6416",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "8701 Santa Monica Blvd",
                    "addressLocality": "West Hollywood",
                    "addressRegion": "CA",
                    "postalCode": "90069",
                    "addressCountry": "US"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 34.08830767274865,
                    "longitude": -118.38027976930773
                },
                "openingHoursSpecification": [{
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": "Monday",
                    "opens": "12:30",
                    "closes": "17:00"
                },{
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": "Wednesday",
                    "opens": "07:00",
                    "closes": "21:00"
                },{
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": "Friday",
                    "opens": "02:30",
                    "closes": "19:00"
                },{
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": "Sunday",
                    "opens": "13:00",
                    "closes": "17:00"
                }] 
                }
            `}
            </script>
        </Helmet>
        <Hero />
        <Customers />
        <Intro />
        <Aging />
        <TwoServices />
        <Botox />
        <Laser />
        <Services />
        <Store />
        <Team />
        <Works />
    </Fragment>
);

export default Home;
