import { Article } from '@/Article';
import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { useRef } from 'react';

const Section = styled(Article)`
    background-color: ${({ theme }) => theme.colors.pink3};
    color: ${({ theme }) => theme.colors.green2};
    text-align: center;
    max-width: unset !important;

    > h2 {
        margin: 0 0 56px;
        color: ${({ theme }) => theme.colors.green2};
    }

    > a {
        margin-top: 56px;
    }

    @media (min-width: 1024px) {
        > h2 {
            margin-bottom: 96px;
        }

        > a {
            margin-top: 96px;
        }
    }

    @media (min-width: 1280px) {
        > h2 {
            margin-bottom: 120px !important;
        }

        > a {
            margin-top: 120px;
        }
    }
`;

export const CardsWorks = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 24px;
    overflow-x: auto;
    overflow-y: hidden;

    @media (pointer: fine) {
        overflow-x: hidden;
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(300px, 328px));
        grid-auto-flow: unset;
        justify-content: space-between;
        grid-row-gap: 40px;
        max-width: 1064px;
        margin: 0 auto;
    }
`;

export const CardWorks = styled.div`
    text-align: left;
    width: 218px;
    flex-shrink: 0;

    > .gatsby-image-wrapper {
        height: 230px;
    }

    > h4 {
        margin: 16px 0;
    }

    > p {
        color: ${({ theme }) => theme.colors.gray3};
        margin: 0;
    }

    @media (min-width: 1024px) {
        width: 328px;
        max-width: 100%;

        > .gatsby-image-wrapper {
            height: 317px;
        }
    }
`;

export const Works = () => {
    type DataType = {
        allFile: {
            nodes: ImageDataLike[];
        };
    };

    const data: DataType = useStaticQuery(graphql`
        query worksQuery {
            allFile(
                filter: { relativeDirectory: { eq: "homepage/works" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 328, height: 317)
                    }
                }
            }
        }
    `);

    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLDivElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    const thirdRef = useRef<HTMLDivElement | null>(null);
    const thirdReveal = useIntersectionObserver(thirdRef, {});

    const fourthRef = useRef<HTMLDivElement | null>(null);
    const fourthReveal = useIntersectionObserver(fourthRef, {});

    const fifthRef = useRef<HTMLDivElement | null>(null);
    const fifthReveal = useIntersectionObserver(fifthRef, {});

    const sixthRef = useRef<HTMLDivElement | null>(null);
    const sixthReveal = useIntersectionObserver(sixthRef, {});

    const text = [
        {
            title: 'Juvederm Upper Lip enhancement',
            text: 'Juvederm added volume to upper lip for balance',
            ref: firstRef,
            className: `reveal${firstReveal ? ' revealed' : ''}`,
        },
        {
            title: 'Restylane Kysse Lip Injectable',
            text: 'Restylane Kysse Lip Injectable',
            ref: secondRef,
            className: `reveal reveal-delay-mobile-1${secondReveal ? ' revealed' : ''}`,
        },
        {
            title: 'Restylane Kysse Lip Injectable',
            text: 'Restylane Kysse Lip Injectable',
            ref: thirdRef,
            className: `reveal reveal-delay-mobile-2${thirdReveal ? ' revealed' : ''}`,
        },
        {
            title: 'Sculptra for Skin Texture',
            text: 'skin elasticity, fine lines, laxity improvement post Sculptra',
            ref: fourthRef,
            className: `reveal reveal-delay-mobile-3${fourthReveal ? ' revealed' : ''}`,
        },
        {
            title: 'Juvederm For  Undereyes Depression',
            text: 'Juvederm injection for under eye tear through',
            ref: fifthRef,
            className: `reveal reveal-delay-mobile-4${fifthReveal ? ' revealed' : ''}`,
        },
        {
            title: 'Botox for Neck',
            text: 'Botox for the neck to improve the appearance of neck skin, and reduce sagging',
            ref: sixthRef,
            className: `reveal reveal-delay-mobile-5${sixthReveal ? ' revealed' : ''}`,
        },
    ];

    const content = data.allFile.nodes.map((node, i) => ({
        image: getImage(node) as IGatsbyImageData,
        ...text[i],
    }));

    return (
        <Section>
            <h2>We’re proud to present you our works</h2>
            <CardsWorks>
                {content.map((card, i) => (
                    <CardWorks key={i} ref={card.ref} className={card.className}>
                        <GatsbyImage image={card.image} alt="beauty religion work" />
                        <h4>{card.title}</h4>
                        <p>{card.text}</p>
                    </CardWorks>
                ))}
            </CardsWorks>
            <SecondaryBtn as={CLink} to={getNavLink('photo gallery')}>
                View Full Gallery
            </SecondaryBtn>
        </Section>
    );
};
