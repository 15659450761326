import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { flexSpace, px } from '@/layout/styles/classes';
import { ReactComponent as Arrow } from '@a/icons/arrow.svg';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';

const Section = styled.section`
    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-end;
        max-width: 1284px;
    }

    @media (min-width: 1600px) {
        margin-left: 9vw;
    }
`;

const Box = styled.div`
    ${px};
    max-width: 602px;

    > h2 {
        color: ${({ theme }) => theme.colors.green2};
        margin: 32px 0 28px;
    }

    > p {
        margin: 0;
    }

    > a {
        margin: 32px 0;

        svg {
            margin-left: 18px;
        }
    }

    > .gatsby-image-wrapper {
        margin: 0 auto;
    }

    @media (min-width: 1024px) {
        max-width: 522px;
        padding: 0 !important;
        margin: 0 32px 56px;
        flex-shrink: 2.5;

        > h2 {
            margin-top: 0;
        }

        > a {
            margin: 64px 0 50px;
        }

        > .gatsby-image-wrapper {
            margin-right: 0;
        }
    }
`;

export const Store = () => {
    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, { threshold: 0 });

    return (
        <Section>
            <div ref={firstRef} className={`reveal-slide-left${firstReveal ? ' revealed' : ''}`}>
                <StaticImage
                    src="../../../assets/images/homepage/products/1.jpg"
                    alt="beauty religion products"
                    width={602}
                    height={877}
                />
            </div>

            <Box
                ref={secondRef}
                className={`reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`}
            >
                <h2>Beauty Simplified and at your doorstep</h2>
                <p>Visit our store for access to all products in our catalog.</p>
                <SecondaryBtn as={CLink} to={getNavLink('shop')}>
                    View All Products <Arrow />
                </SecondaryBtn>
                <StaticImage
                    src="../../../assets/images/homepage/products/2.jpg"
                    alt="alumiere boost serum"
                    width={217}
                    height={333}
                    layout="fixed"
                />
            </Box>
        </Section>
    );
};
