import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { ImageBorder } from '@/ImageBorder';
import { info } from '@/layout/NavLinks';
import { flexCenter, px } from '@/layout/styles/classes';
import { ReactComponent as Edit } from '@a/icons/edit.svg';
import { ReactComponent as Star } from '@a/icons/star.svg';
import styled from '@emotion/styled';
import { useDragScroll } from '@s/hooks/useDragScroll';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { useRef } from 'react';

const Section = styled.section`
    background-color: ${({ theme }) => theme.colors.pink3};
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;

    > div:first-of-type {
        > h2 {
            ${px};
            margin: 0;
            color: ${({ theme }) => theme.colors.green2};
        }

        > h4 {
            ${flexCenter};
            margin: 20px 0 40px;

            > svg {
                margin-right: 3px;

                :last-of-type {
                    margin-right: 16px;
                }
            }
        }
    }

    > a {
        margin-top: 44px;

        svg {
            margin-left: 18px;
        }
    }

    @media (min-width: 1024px) {
        > div:first-of-type > h4 {
            margin: 40px 0 80px;
        }
    }

    @media (min-width: 1280px) {
        padding-top: 160px;
        padding-bottom: 160px;

        > div {
            max-width: fit-content;
            margin: 0 auto;

            > h4 {
                margin-bottom: 120px;
            }
        }

        > a {
            margin-top: 120px;
        }
    }
`;

const Cards = styled.div`
    ${px};
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    @media (pointer: fine) {
        overflow-x: hidden;
    }

    @media (min-width: 1280px) {
        padding-left: 32px;
        padding-right: 32px;
        cursor: unset !important;
    }
`;

const Card = styled.div`
    width: 223px;
    flex-shrink: 0;

    :not(:last-of-type) {
        margin-right: 40px;
    }

    @media (min-width: 1024px) {
        width: 237px;

        :not(:last-of-type) {
            margin-right: 64px;
        }
    }

    @media (min-width: 1280px) {
        ${flexCenter};
        justify-content: flex-start;
        flex-direction: column;

        :not(:last-of-type) {
            margin-right: 80px;
        }

        :nth-of-type(even) {
            > div:last-of-type {
                order: -1;
                margin-top: 0;
                margin-bottom: 40px;
            }
        }
    }

    @media (min-width: 1320px) {
        :not(:last-of-type) {
            margin-right: 96px;
        }
    }
`;

const CardContent = styled.div`
    margin-top: 53px;

    > div {
        ${flexCenter};
        margin-bottom: 16px;

        > svg:not(:last-of-type) {
            margin-right: 8px;
        }
    }

    > p {
        color: ${({ theme }) => theme.colors.gray3};
        margin-bottom: 0;

        > span {
            flex-shrink: 0;
        }

        :last-of-type {
            ${flexCenter};

            ::before,
            ::after {
                content: '';
                background-color: ${({ theme }) => theme.colors.gray3};
                height: 1px;
                width: 100%;
            }

            ::before {
                margin-right: 16px;
            }

            ::after {
                margin-left: 16px;
            }
        }
    }

    @media (min-width: 1280px) {
        margin-top: 40px;
    }
`;

export const Customers = () => {
    type DataType = {
        allFile: {
            nodes: ImageDataLike[];
        };
    };

    const data: DataType = useStaticQuery(graphql`
        query customersImages {
            allFile(
                filter: { relativeDirectory: { eq: "homepage/customers" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 226, height: 332, layout: FIXED)
                    }
                }
            }
        }
    `);

    const images = data.allFile.nodes.map(node => getImage(node) as IGatsbyImageData);

    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLDivElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    const thirdRef = useRef<HTMLDivElement | null>(null);
    const thirdReveal = useIntersectionObserver(thirdRef, {});

    const fourthRef = useRef<HTMLDivElement | null>(null);
    const fourthReveal = useIntersectionObserver(fourthRef, {});

    const reviews = [
        {
            text: 'Yeliana is gifted. She is as kind and beautiful as she is talented. Magic woman with skin and beauty. I call her a goddess!  She’s more knowledgeable about skin than any doctor I’ve ever met. My goal is to someday have skin like hers, even toned and bright. She’s ageless. You will feel that way too!',
            name: 'Ilene Wendy',
            ref: firstRef,
            className: `reveal${firstReveal ? ' revealed' : ''}`,
        },
        {
            text: 'Yeliana is amazing! They have the best energy and made me feel completely confident and taken care of. I  couldn’t believe the improvement I saw after one IPL treatment, and I already scheduled two more visits this month. I can’t wait to go back!',
            name: 'Lindsey Lowe',
            ref: secondRef,
            className: `reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`,
        },
        {
            text: 'I went here to get my lips done for the first time ever and am so happy with the results! The injections were painless, and my lips turned out great, not only that but everyone there was so sweet and welcoming, I’ll 100% be coming back here for future work done!',
            name: 'Braeden Hall',
            ref: thirdRef,
            className: `reveal reveal-delay-2${thirdReveal ? ' revealed' : ''}`,
        },
        {
            text: 'So happy I found Yeliana! She has been a life saver for my undereye bags!! She’s so sweet and professional and really knows what she is doing. I really appreciate her after work hours and she is always very accommodating to my schedule.',
            name: 'Kelly Russell',
            ref: fourthRef,
            className: `reveal reveal-delay-3${fourthReveal ? ' revealed' : ''}`,
        },
    ];

    const reviewsImages = reviews.map((data, i) => ({
        image: images[i],
        ...data,
    }));

    const ref = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDragScroll(ref);

    return (
        <Section>
            <div>
                <h2>kind words from our customers</h2>
                <h4>
                    <Star />
                    <Star />
                    <Star />
                    <Star />
                    <Star /> 5.0 on Yelp
                </h4>
                <Cards ref={ref} {...events}>
                    {reviewsImages.map((card, i) => (
                        <Card key={i}>
                            <ImageBorder big>
                                <GatsbyImage image={card.image} alt="beautiful woman" />
                            </ImageBorder>
                            <CardContent ref={card.ref} className={card.className}>
                                <div>
                                    {Array.from({ length: 5 }).map((_, i) => (
                                        <Star key={i} />
                                    ))}
                                </div>
                                <p>{card.text}</p>
                                <p>
                                    <span>{card.name}</span>
                                </p>
                            </CardContent>
                        </Card>
                    ))}
                </Cards>
            </div>
            <SecondaryBtn as={CLink} to={info.reviewLink}>
                Please Leave us a Review <Edit />
            </SecondaryBtn>
        </Section>
    );
};
